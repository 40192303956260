@font-face {
  font-family: FredokaRegular;
  src: url(./fonts/Fredoka-Regular.ttf);

}


@font-face {
  font-family: FredokaSemiBold;
  src: url(./fonts/Fredoka-SemiBold.ttf);

}

@font-face {
  font-family: FredokaMedium;
  src: url(./fonts/Fredoka-Medium.ttf);

}

@font-face {
  font-family: FredokaBold;
  src: url(./fonts/Fredoka-Bold.ttf);

}


